@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import '../node_modules/font-awesome/css/font-awesome.min.css';

html, body, div, p, a, span, h1, h2, h3, h4, h5 {
    font-family: 'Tajawal', sans-serif;
}

